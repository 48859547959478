<template>
  <div>
    <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <div style="margin: 16px;">
      <span>签约信息:</span>
      <div style="margin: 16px;">
        <van-row span="24">
          <van-col span="6">合同名称:</van-col>
          <van-col span="18">{{selectCompanyData.templateName}}</van-col>
        </van-row>
        <van-row span="24">
          <van-col span="6">甲方:</van-col>
          <van-col span="18">{{inviterName}}</van-col>
        </van-row>
        <van-row span="24">
          <van-col span="6">乙方:</van-col>
          <van-col span="18">{{inviteeName}}</van-col>
        </van-row>
      </div>
    </div>
    <div style="margin: 16px;">
      <span>二维码:</span>
      <div style="margin: 16px;">
        <qrcode-vue :value="linkInfo" :size="qrCodeSize" level="H" />
      </div>
    </div>
    <div style="margin: 16px;">
      <span>链接地址:</span>
      <div style="margin: 16px;">
        <textarea rows="10" cols="30" v-model="linkInfo" readonly>
        </textarea>
      </div>
    </div>
    <div style="margin: 16px;">
      <van-row>
        <van-col span="24"><van-button round block type="info" @click="copyLink()">复制链接</van-button></van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import QrcodeVue from "qrcode.vue";
export default {
  name: "SignInfo",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      selectCompanyData: {},
      companyInfo: {},
      personInfo: {},
      // 邀请方 甲方
      inviterName:'',
      // 受邀方 乙方
      inviteeName:'',
      // 链接信息
      linkInfo: '',
      // 二维码大小
      qrCodeSize: 140,
      QRImgUrl: '',
    }
  },
  created() {
    this.selectCompanyData = this.$route.params.selectCompanyData;
    this.inviterName = this.$route.params.inviterName;
    this.inviteeName = this.$route.params.inviteeName;
    this.linkInfo = this.$route.params.linkInfo;
  },
  methods: {
    // 返回上个页面
    onClickLeft() {
      this.$router.back()
    },
    // 复制链接
    copyLink() {
      const text = document.createElement('textarea'); // 创建节点
      text.setAttribute('readonly', 'readonly');
      text.value = this.linkInfo; // 赋值
      document.body.appendChild(text); // 插入节点
      text.setSelectionRange(0, text.value.length);
      text.select(); // 选中节点
      document.execCommand('copy'); // 执行浏览器复制方法
      if (document.body.removeChild(text)) {
        Toast.success('复制成功');
      } else {
        Toast.fail('复制失败');
      }
    }
  }
}
</script>

<style scoped>

</style>