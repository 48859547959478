<template>
  <div>
    <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <div>
      <van-row span="24" class="sign-row">
        <van-col span="24">
          <van-notice-bar
              left-icon="volume-o"
              text="苹果手机用户请在浏览器里打开链接自行保存下载。"
          />
        </van-col>
      </van-row>
    </div>
    <div>
      <van-row span="24">
        <van-col span="18">
          <van-field
              readonly
              clickable
              name="datetimePicker"
              :value="finishDateBegin"
              label="签约日期(起):"
              placeholder="点击选择签约日期(起)"
              @click="finishDateBeginShowPicker = true"
          />
          <van-popup v-model="finishDateBeginShowPicker" position="bottom">
            <van-datetime-picker
                type="date"
                @confirm="finishDateBeginConfirm"
                cancel-button-text="清除"
                @cancel="finishDateBeginCancel"
                :min-date="minDate"
                :max-date="maxDate"
            />
          </van-popup>
        </van-col>
        <van-col span="6">
<!--          <van-button color="dodgerblue" style="height: 35px">查询</van-button>-->
        </van-col>
      </van-row>
      <van-row span="24">
        <van-col span="18">
          <van-field
              readonly
              clickable
              name="datetimePicker"
              :value="finishDateEnd"
              label="签约日期(止):"
              placeholder="点击选择签约日期(止)"
              @click="finishDateEndShowPicker = true"
              clearable
          />
          <van-popup v-model="finishDateEndShowPicker" position="bottom">
            <van-datetime-picker
                type="date"
                @confirm="finishDateEndConfirm"
                cancel-button-text="清除"
                @cancel="finishDateEndCancel"
                :min-date="minDate"
                :max-date="maxDate"
            />
          </van-popup>
        </van-col>
      </van-row>
      <div class="search-div">
        <van-button color="dodgerblue" style="height: 35px" @click="searchSignList">查询</van-button>
      </div>
    </div>
    <div>
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getSignList"
          style="height: 100%; width: 100%"
          align="center"
      >
        <div v-for="item in signList" :key="item.id" style="padding: 1em; margin: 1em; text-align: left;border: black 1px solid">
          <van-row span="24" class="sign-row"><van-col span="24">{{item.contractName}}</van-col></van-row>
          <van-row span="24" class="sign-row">
            <van-col span="6">签约时间:</van-col>
            <van-col span="18">{{item.finishTime}}</van-col>
          </van-row>
          <van-row span="24" class="sign-row">
            <van-col span="6">签约乙方:</van-col>
            <van-col span="18">{{item.inviteeName}}</van-col>
          </van-row>
          <van-row span="24" class="sign-row">
            <van-col span="10" offset="2"><a href="javascript:void(0);" @click="signPreview(item)">合同预览</a></van-col>
            <van-col span="10" offset="2"><a href="javascript:void(0);" @click="downLoadSign(item)">下载合同</a></van-col>
          </van-row>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
// 请求信息api
import signListApi from "@/api/signList";
import download from 'downloadjs'
export default {
  name: "SignList",
  data () {
    return {
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(2099, 11, 31),
      finishDateBegin: '',
      finishDateEnd: '',
      finishDateBeginShowPicker: false,
      finishDateEndShowPicker: false,
      loading: false,
      finished: false,
      signList: [],
      pageNum: 1
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    finishDateBeginConfirm(time) {
      //获取年月日
      this.finishDateBegin = this.getDate(time);
      this.finishDateBeginShowPicker = false;
    },
    finishDateEndConfirm(time) {
      this.finishDateEnd = this.getDate(time);
      this.finishDateEndShowPicker = false;
    },
    getDate(time) {
      const year = time.getFullYear();
      const mon = (time.getMonth()+1) < 10 ? "0"+(time.getMonth()+1) : time.getMonth()+1;
      const day = time.getDate()  < 10 ? "0"+(time.getDate()) : time.getDate();
      const date = year +"-"+ mon +"-"+ day;
      return date;
    },
    finishDateBeginCancel() {
      this.finishDateBegin = '';
      this.finishDateBeginShowPicker = false;
    },
    finishDateEndCancel() {
      this.finishDateEnd = '';
      this.finishDateEndShowPicker = false;
    },
    // 获取签约记录信息
    getSignList() {

      if (this.finishDateBegin && this.finishDateEnd) {
        const finishDateBeginDate = new Date(this.finishDateBegin);
        const finishDateEndDate = new Date(this.finishDateEnd);
        if (finishDateBeginDate > finishDateEndDate) {
          Toast.fail('开始时间不能大于结束时间');
          this.signList = [];
          return ;
        }
      }
      const param = {};
      param.finishDateBegin = this.finishDateBegin;
      param.finishDateEnd = this.finishDateEnd;
      param.loginType = sessionStorage.getItem('loginType');
      const query = {};
      query.pageSize = 3;
      query.pageNum = this.pageNum;

        signListApi.getSignList(query, param).then((res) => {
          if (res.rows.length > 0) {
            if (this.signList.length < res.total) {
              this.finished = false;
              this.pageNum += 1;
              this.signList = this.signList.concat(res.rows);
              this.loading = false;
              if (this.signList.length === res.total) {
                this.finished = true;
                this.pageNum = 1;
              }
            }
          } else {
            this.signList = [];
            this.finished = true;
          }
        });
    },
    // 合同预览
    signPreview(item) {
      const param = {};
      param.inviteId = item.inviteId;
      // 添加loginType 区分甲方乙方登录来获取对应有效的OSS地址
      param.loginType = sessionStorage.getItem('loginType');
      signListApi.getValidSignPdfUrl(param).then((res) => {
        if (res.data) {
          this.$router.push({
                name:"SignPreview",
                params:{
                  pdfSrc: res.data.contractUrl,
                },
              }
          );
        } else {
          Toast.fail(res.msg);
        }
      })
    },
    // 合同下载
    downLoadSign(item) {
      const param = {};
      param.inviteId = item.inviteId;
      // 添加loginType 区分甲方乙方登录来获取对应有效的OSS地址
      param.loginType = sessionStorage.getItem('loginType');
      signListApi.getValidSignPdfUrl(param).then((res) => {
        if (res.data) {
          download(res.data.contractUrl);
          Toast.success('下载成功');
        } else {
          Toast.fail('下载失败');
        }
      })
    },
    // 查询按钮点击方法
    searchSignList() {
      this.pageNum = 1;
      this.signList = [];
      this.finished = false;
      this.getSignList();
    }
  }
}
</script>

<style scoped>
.search-div {
  float: right;
  position: relative;
  margin-top: -4em;
  margin-right: 1em;
}
.sign-row {
  padding: 5px;
}
</style>
