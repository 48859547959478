<template>
    <div>
        <br/><br/>
        <br/><br/>
        <van-row type="flex" justify="center">
            <van-col span="12" >
                <van-image
                        :src="require('@/assets/logo.png')"
                />
            </van-col>
        </van-row>
        <br/><br/>

        <van-form >
            <van-row>
                <van-col span="24">
                    <van-field
                            v-model="phone"
                            name="手机号"
                            label="手机号"
                            placeholder="手机号"
                            type="number"
                    />
                </van-col>
                <br/><br/>
            </van-row>
            <van-row>
                <van-col span="16">
                    <van-field
                            v-model="smsCode"
                            name="短信验证码"
                            label="短信验证码"
                            placeholder="短信验证码"
                            type="number"
                    />
                </van-col>
                <van-col span="8">  
                    <van-count-down :time="1000 * 60" format="ss s" @finish="isSendCode= false" v-if="isSendCode"/>
                    <van-button v-else @click="getPictureCode" native-type="button" round size="small">发送验证码</van-button>
                </van-col>
            </van-row>

            <div style="margin: 16px;">
                <van-button round block @click="onSubmit" type="info" native-type="submit" :disabled="btnState">提交</van-button>
            </div>
        </van-form>
        <van-dialog v-model="phoneCodeShow" title="验证码" show-cancel-button @confirm="onSendSms" :before-close="beforeClose">
          <van-form>
            <van-row>
              <van-col span="16">
                <van-field
                    v-model="pictureCode"
                    name="验证码"
                    placeholder="验证码"
                    maxlength="5"
                    type="number"
                    required
                />
              </van-col>
              <van-col span="8">
                <img :src="pictureCodeUrl" @click="getPictureCode"/>
              </van-col>
            </van-row>
          </van-form>
        </van-dialog>
    </div>
</template>

<script>
    import urlUtils from "@/utils/urlParamsUtil";
    import validate from "@/utils/validate";
    import loginApi from "@/api/login";
    import { Toast } from 'vant';
    import router from "../router";
    export default {
        name: "Login",
        data() {
            return {
                phone: "",
                smsCode: "",
                isSendCode: false,
                companyId:"",
                btnState: true,
                phoneCodeShow: false,
                pictureCode: "",
                pictureCodeUrl: ""
            }
        },
        methods:{
            onSubmit (values) {
                let phoneErr = validate.validatePhone(this.phone)
                if(phoneErr !== true) {
                    Toast.fail(phoneErr.phone)
                }else{
                    let smsCodeErr = validate.validateSMS(this.smsCode)
                    if(smsCodeErr !== true){
                        Toast.fail(smsCodeErr.sms)
                    }else{
                        let loginData ={"phone":this.phone,"companyId":this.companyId,smsCode:this.smsCode}
                        loginApi.login(loginData).then(res=>{
                            sessionStorage.setItem("token",res.data.token)
                            router.replace({
                                path:'/companySign'
                            });
                        }).catch(err=>{})

                    }
                }


            },
          getPictureCode() {
            // 如果手机号不正确给处提示
            let phoneErr = validate.validatePhone(this.phone)
            if(phoneErr !== true){
              Toast.fail(phoneErr.phone)
              return ;
            }
            if (!this.phoneCodeShow) {
              this.phoneCodeShow = true;
              this.pictureCode = "";
            }
            loginApi.getPictureCode().then(res => {
              if(res.code == 0){
                this.pictureCodeUrl = res.data.picBase64;
              };
            }).catch(err => {console.log(err)})
          },
          beforeClose(action, done) {
            if(action === 'confirm'){
              if (!this.pictureCode) {
                Toast.fail("验证码不能为空")
                return done(false);
              }
            }
            return done();
          },
          onSendSms () {
                let phoneErr = validate.validatePhone(this.phone)
                if(phoneErr !== true){
                    Toast.fail(phoneErr.phone)
                }else {
                    let data ={"phone":this.phone,"companyId":this.companyId,"pictureCode":this.pictureCode}
                    loginApi.smsSend(data).then(res => {
                        this.isSendCode = true;
                    }).catch(err => {console.log(err)})
                }
            }
        },
        created(){
            let cs_res = urlUtils.getParams(window.location.href)
            if(cs_res === null || cs_res['inviteType'] === undefined || cs_res['companyId'] === undefined){
                Toast.fail('您的登录链接有误，请重新获取登录链接');
            }else{
                this.btnState = false;
                this.companyId = cs_res['companyId'];
                sessionStorage.setItem("companyId",this.companyId)
                sessionStorage.setItem("inviteType",cs_res['inviteType'])
                sessionStorage.setItem("loginType","1")//甲方登录
            }

        }
    }
</script>

<style scoped>

</style>
