import request from '@/router/request'

export default {
    // 获取签约列表
    getSignPdfUrl(inviteId) {
        return request({
            url: `/foreground/web/companySignTool/getSignDetailByInviteId/${inviteId}`,
            method: 'get'
        })
    },
    // 签约
    signPdf(inviteId) {
        return request({
            url: `/foreground/web/companySignTool/finishCompanySign/${inviteId}`,
            method: 'get'
        })
    },
}