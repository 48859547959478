import Vue from 'vue'
import App from './App'
import router from './router'
import axios from 'axios'
import Vant from 'vant'
import 'vant/lib/index.css';

Vue.config.productionTip = false; /* vue 在启动时生成生产提示 */
Vue.prototype.$axios = axios;
Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  render: h => h(App),
}).$mount('#app')
