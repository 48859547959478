<template>
    <div>
        <br/><br/>
        <br/><br/>
        <van-row type="flex" justify="center">
            <van-col span="12" >
                <van-image
                        :src="require('@/assets/logo.png')"
                />
            </van-col>
        </van-row>
        <br/><br/>
        <van-form >
            <van-row>
                <van-col span="16">
                    <van-field
                            v-model="smsCode"
                            name="短信验证码"
                            label="短信验证码"
                            placeholder="短信验证码"
                            type="number"
                    />
                </van-col>
                <van-col span="8">  
                    <van-count-down :time="1000 * 60" format="ss s" @finish="isSendCode= false" v-if="isSendCode"/>
                    <van-button v-else @click="getPictureCode" native-type="button" round size="small">发送验证码</van-button>
                </van-col>
            </van-row>
            <van-row>
                <van-col span="24">
                    <van-notice-bar
                            left-icon="volume-o"
                            text="请与邀请人确认您的接收短信的手机号。"
                    />
                </van-col>
            </van-row>
            <div style="margin: 16px;">
                <van-button round block @click="onSubmit" type="info" native-type="submit" :disabled="btnState">提交</van-button>
            </div>
        </van-form>
      <van-dialog v-model="phoneCodeShow" title="验证码" show-cancel-button @confirm="onSendSms" :before-close="beforeClose">
        <van-form>
          <van-row>
            <van-col span="16">
              <van-field
                  v-model="pictureCode"
                  name="验证码"
                  placeholder="验证码"
                  maxlength="5"
                  type="number"
                  required
              />
            </van-col>
            <van-col span="8">
              <img :src="pictureCodeUrl" @click="getPictureCode"/>
            </van-col>
          </van-row>
        </van-form>
      </van-dialog>
    </div>
</template>

<script>
    import urlUtils from "@/utils/urlParamsUtil";
    import validate from "@/utils/validate";
    import loginApi from "@/api/login";
    import { Toast } from 'vant';
    import router from "../router";
    export default {
        name: "SubLogin",
        data() {
            return {
                smsCode: "",
                isSendCode: false,
                inviteId:"",
                btnState: true,
                phoneCodeShow: false,
                pictureCode: "",
                pictureCodeUrl: ""
            }
        },
        methods:{
            onSubmit (values) {
                    let smsCodeErr = validate.validateSMS(this.smsCode)
                    if(smsCodeErr !== true){
                            Toast.fail(smsCodeErr.sms)
                    }else{
                        let loginData ={"inviteId":this.inviteId,smsCode:this.smsCode}
                        loginApi.inviteeLogin(loginData).then(res=>{
                            sessionStorage.setItem("token",res.data.token)
                            sessionStorage.setItem("inviteFinishFlag",res.data.inviteFinishFlag)
                            let nextPath = "/sign"
                            if (res.data.inviteFinishFlag){
                                nextPath = "/contractFinish"
                            }
                            router.replace({
                                path: nextPath
                            });
                        }).catch(err=>{})
                    }
                },
            getPictureCode() {
              if (!this.phoneCodeShow) {
                this.phoneCodeShow = true;
                this.pictureCode = "";
              }
              loginApi.getPictureCode().then(res => {
                if(res.code == 0){
                  this.pictureCodeUrl = res.data.picBase64;
                };
              }).catch(err => {console.log(err)})
            },
            beforeClose(action, done) {
              if(action === 'confirm'){
                if (!this.pictureCode) {
                  Toast.fail("验证码不能为空")
                  return done(false);
                }
              }
              return done();
            },
            onSendSms () {
                let data ={"inviteId":this.inviteId,"pictureCode":this.pictureCode}
                loginApi.inviteeSend(data).then(res => {
                    this.isSendCode = true;
                }).catch(err => {})
            }
        },
        created(){
            let cs_res = urlUtils.getParams(window.location.href)
            if(cs_res === null || cs_res['inviteId'] === undefined){
                Toast.fail('您的登录链接有误，请重新获取登录链接');
            }else{
                this.btnState = false;
                this.inviteId = cs_res['inviteId'];
                sessionStorage.setItem("inviteId",this.inviteId)
                sessionStorage.setItem("loginType","2")//乙方登录
            }

        }
    }
</script>

<style scoped>

</style>
