<template>
  <div>
      <div class="scrollBox" @scroll="scroll">
        <van-loading v-if="showLoading" size="30px" vertical style="margin-top: 50%">加载中...</van-loading>
        <pdf v-for="item in numPages" :key="item" :src="pdfSrc" :page="item" ref="pdf" @page-loaded="lastPageLoad"></pdf>
      </div>
      <div style="margin: 16px;">
        <van-row>
          <van-col span="24"><van-button round block type="info" @click="sign()" :disabled="submitShowFlag">同意并签署</van-button></van-col>
        </van-row>
      </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import {Toast} from "vant";
import signApi from "@/api/sign";
export default {
  name: "Sign",
  components:{
    pdf
  },
  data() {
    return {
      username: '',
      password: '',
      pdfSrc:"",
      numPages: null, // pdf 总页数
      submitShowFlag: true,
      showLoading: true,
      inviteId: "",
    }
  },
  created() {
    this.getSignPdfUrl();
  },
  mounted() {
  },
  methods : {
    // 获取签约pdf链接信息
    getSignPdfUrl() {
      this.inviteId = sessionStorage.getItem("inviteId");
      signApi.getSignPdfUrl(this.inviteId).then((res) => {
        if (res.data) {
          this.pdfSrc = res.data.templateUrl;
          this.$nextTick(()=> {
            this.getNumPages();
          })
        } else {
          Toast.fail('pdf 获取失败');
          return ;
        }
      })
    },
    // 计算pdf页码总数
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.pdfSrc)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
        if (this.numPages === 1) {
          this.submitShowFlag = false;
        }
      }).catch(err => {
        Toast.fail('pdf 加载失败');
      })
    },
    lastPageLoad (num) {
      num === this.numPages ? this.showLoading = false : this.showLoading = true
    },
    // 监听滚动条事件
    scroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.submitShowFlag = false;
      } else {
        this.submitShowFlag = true;
      }
    },
    // 同意并签署
    sign() {
      Toast.loading({
        message: '签约中...',
        forbidClick: true,
        duration: 0
      });
      this.inviteId = sessionStorage.getItem("inviteId");
      signApi.signPdf(this.inviteId).then((res) => {
        const msg = res.msg;
        if (res.code === 0) {
          this.$router.push(
              {
                name:"ContractFinish",
              }
          )
          Toast.clear();
          Toast.success(msg);
        } else {
          Toast.clear();
          Toast.fail(msg);
        }
      })
    }
  }
}
</script>

<style scoped>
.scrollBox{
  width: 100%;
  height: 650px;
  overflow-y: scroll;
}
</style>
