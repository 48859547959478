<template>
  <div class="main-div" style="height: 100%">
    <!--头区域-->
    <div style="margin: 16px;">
      <span>请选择一个要签署的合同：</span>
    </div>
    <!--列表区域-->
    <div class="box-vanList">
      <van-radio-group v-model="selectCompanyData">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getCompanyContactList"
            style="height: 100%; width: 100%"
            align="center"
        >
          <div class="box-list">
              <van-cell v-for="item in companyContractList" :key="item.id" :title="item.templateName" clickable @click="selectData(item)">
                <template #right-icon>
                  <van-radio :name="item" />
                </template>
              </van-cell>
          </div>
        </van-list>
      </van-radio-group>

    </div>
    <!--表单区域-->
    <div style="margin: 16px;">
      <!-- 1: 企业 0: 个人-->
      <span v-if="companyOrPersonFlag == 1">选择签约的乙方:</span>
      <span v-if="companyOrPersonFlag == 0">填写签约的乙方:</span>
      <van-form v-if="companyOrPersonFlag == 1" @submit="companySignSubmit">
        <van-row>
          <van-col span="24">
            <van-field
                v-model="companyForm.userCard"
                name="企业营业执照"
                label="企业营业执照:"
                placeholder="请填写企业营业执照证件号"
                @blur="getCompanyInfo"
                :rules="rules.companyUserCard"
                label-width="7.2em"
            />
          </van-col>
        </van-row>
        <van-row v-if="companyInfoFlag">
          <van-col span="24">
            <van-field
                v-model="companyInfo.userName"
                name="企业名称"
                label="企业名称:"
                label-width="7.2em"
                readonly
            ></van-field>
          </van-col>
        </van-row>
        <van-row v-if="companyInfoFlag">
          <van-col span="24">
            <van-field
                v-model="companyInfo.legalName"
                name="企业法人姓名"
                label="企业法人姓名:"
                label-width="7.2em"
                readonly
            ></van-field>
          </van-col>
        </van-row>
        <van-row v-if="companyInfoFlag">
          <van-col span="24">
            <van-field
                v-model="encryptCompanyUserCard"
                name="企业法人证件号"
                label="企业法人证件号:"
                label-width="8.2em"
                readonly
            ></van-field>
          </van-col>
        </van-row>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">生成签约二维码</van-button>
        </div>
      </van-form>
      <van-form v-if="companyOrPersonFlag == 0" @submit="companySignSubmit">
        <van-row>
          <van-col span="24">
            <van-field
                v-model="personForm.userCard"
                name="证件号"
                label="证件号:"
                placeholder="请填写证件号"
                :rules="rules.personUserCard"
                @blur="getPersonInfo"
            />
          </van-col>
        </van-row>
        <van-row v-if="personInfoFlag">
          <van-col span="24">
            <van-field
                v-model="personForm.userName"
                name="姓名"
                label="姓名:"
                :rules="rules.personUserName"
                readonly
            />
          </van-col>
        </van-row>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">生成签约二维码</van-button>
        </div>
      </van-form>
    </div>
    <div>
      <div class="bottom-div">
        <van-row span="24" style="margin-top: 5px">
          <van-col offset="1" span="17" style="color: aliceblue;">已累计签约记录({{signCount}}份)</van-col>
          <van-col span="6">
            <a href="javascript:void(0);" @click="toSignList">点击查看>></a>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
// 请求信息api
import companySignApi from "@/api/companySign";

export default {
  name: "CompanySign",
  data() {
    return {
      companyContractList: [],
      selectCompanyData: {},
      loading: false,
      finished: false,
      companyOrPersonFlag: 1,
      companyForm: {
        // 企业证件号
        userCard: '',
      },
      personForm: {
        userName: '',
        userCard: '',
      },
      companyInfo: {
      },
      // 企业其他信息显示标识
      companyInfoFlag: false,
      // 个人信息显示标识
      personInfoFlag: false,
      rules: {
            companyUserCard: [{
              required: true,
            }],
            personUserName: [{
              required: true,
            }],
            personUserCard: [{
              required: true,
            }]
      },
      signCount: 0,
      companyId: '',
      encryptPersonUserCard: "",
      encryptCompanyUserCard: ""
    }
  },
  created() {
    // 设置企业和个人
    this.setCompanyOrPersonFlag();
    // 获取签约合同数
    this.getSignCount();
    // 企业id赋值
    this.companyId = sessionStorage.getItem('companyId');
  },
  methods: {
    // 设置企业和个人
    setCompanyOrPersonFlag() {
      if (sessionStorage.getItem('inviteType') == 10) {
        this.companyOrPersonFlag = 1;
      }
      if (sessionStorage.getItem('inviteType') == 20) {
        this.companyOrPersonFlag = 0;
      }
    },
    // 获取企业合同列表
    getCompanyContactList() {
      companySignApi.getCompanyContactList().then((res) => {
        this.companyContractList = res.data;
        this.finished = true;
      });
    },
    // 获取企业信息
    getCompanyInfo() {
      if (this.companyForm.userCard) {
        companySignApi.getCompanyInfo(this.companyForm.userCard).then((res) => {
          if (res) {
            this.companyInfoFlag = true;
            this.companyInfo = res.data
            // 从身份证的第5位(下标为4)开始，提取11位数字
            const temp = this.companyInfo.legalIdentityNo.substr(4, 11)
            // 将身份证中中间的11位替换成***********（11个*号）
            this.encryptCompanyUserCard = this.companyInfo.legalIdentityNo.replace(temp, '***********')
          } else {
            Toast('企业信息有误');
          }
        })
      } else {
        this.companyInfoFlag = false;
        this.companyInfo = {}
      }
    },
    // 根据身份证号获取个人信息
    getPersonInfo() {
      if (this.personForm.userCard) {
        companySignApi.getPersonInfo(this.personForm.userCard).then((res) => {
          if (res) {
            this.personInfoFlag = true;
            this.personForm.userName = res.data.userName
          } else {
            Toast('个人信息有误');
          }
        })
      } else {
        this.personInfoFlag = false;
        this.personForm = {}
      }
    },
    // 获取选中模版数据
    selectData(companyTemplate) {
      this.selectCompanyData = companyTemplate;
    },
    // 企业获取二维码
    companySignSubmit() {
      // 模版非空拦截
      if (Object.keys(this.selectCompanyData).length == 0) {
        Toast.fail('请选择模版');
        return ;
      }
      const param = {};
      let linkInfo = '';
      // 企业
      if (this.companyOrPersonFlag == 1) {
        param.code = this.companyForm.userCard;
        param.inviteType = 10;
      }
      // 个人
      if (this.companyOrPersonFlag == 0) {
        param.code = this.personForm.userCard;
        param.inviteType = 20;
      }
      param.templateId = this.selectCompanyData.id;
      companySignApi.createCompanySign(param).then((res) => {
        if (res.data) {
          // 路由跳转签约信息页
          this.$router.push({
            name:"SignInfo",
            params:{
              selectCompanyData:this.selectCompanyData,
              inviterName: res.data.createBy,
              inviteeName: res.data.inviteeName,
              linkInfo: process.env.VUE_APP_TOOL_URL + "subLogin?inviteId=" + res.data.id
            }
          })
          // 充值表单
          this.companyForm = {},
          // 选择模版重置
          this.selectCompanyData = {}
        } else {
          Toast.fail('签约失败');
        }
      })
    },
    // 获取签约合同数量
    getSignCount() {
      const loginType = sessionStorage.getItem('loginType');
      companySignApi.getCompanySignCount(loginType).then((res) => {
        this.signCount = res.data
      })
    },
    toSignList() {
      if (this.signCount > 0) {
        this.$router.push(
            {
              name:"SignList",
            }
        )
      } else {
        Toast.fail('无历史签约数据');
      }
    }
  }
}
</script>

<style scoped>
.main-div {
  margin-top: 10px;
}
.box-vanList {
  height: 17rem;
  overflow-y: scroll;
}
.box-list{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom-div {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: dodgerblue;
  height: 35px;
}
</style>
