<template>
    <div class="contractFinishClass">
        <br/>
        <van-row class="signRowClass">
            <van-col span="12" class="signInfoCellClass">签约信息：</van-col>
        </van-row>
        <br/>
        <van-row class="signRowClass">
            <van-col span="6" class="signCellClass">合同名称：</van-col>
            <van-col span="18" class="signCellClass">{{contractName}}</van-col>
        </van-row>
        <br/>
        <van-row class="signRowClass">
            <van-col span="6" class="signCellClass">甲方：</van-col>
            <van-col span="18" class="signCellClass">{{companyName}}</van-col>
        </van-row>
        <br/>
        <van-row class="signRowClass">
            <van-col span="6" class="signCellClass">乙方：</van-col>
            <van-col span="18" class="signCellClass">{{inviteeName}}</van-col>
        </van-row>
        <br/>
        <van-row class="signRowClass">
            <van-col span="6" class="signCellClass">签约时间：</van-col>
            <van-col span="18" class="signCellClass">{{signTime}}</van-col>
        </van-row>
        <br/>
        <van-row class="signRowClass">
            <van-col span="6" class="signCellClass">查看合同：</van-col>
            <van-col span="18" class="signCellClass"><a href="javascript:void(0);"  @click="signPreview()">点击预览</a></van-col>
        </van-row>
        <br/><br/>
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="button" @click="downLoadSign()">下载电子合同</van-button>
            <van-notice-bar
                    left-icon="volume-o"
                    text="苹果手机用户请在浏览器里打开链接自行保存下载。"
            />
        </div>
        <div class="bottom-div">
            <van-row span="24" style="margin-top: 5px">
                <van-col offset="1" span="17" style="color: aliceblue;">已累计签约记录({{signCount}}份)</van-col>
                <van-col span="6">
                    <a href="javascript:void(0);" @click="toSignList">点击查看>></a>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
    import DetailData from '@/api/getInvitDetail.js'
    import companySignApi from "@/api/companySign";
    import download from 'downloadjs'
    import {Toast} from "vant";
    export default {
        name: "ContractFinish",
        data(){
            return{
                url:"",
                companyName:"",
                inviteeName:"",
                signTime:"",
                contractName:"",
                signCount: 0
            }
        },
        created() {
            this.getContract();
            this.getSignCount();
        },
        methods:{
            getContract(){
                const inviteId = sessionStorage.getItem("inviteId");
                DetailData.getDetailData(inviteId).then(res=>{
                    this.url = res.data.contractUrl;
                    this.companyName = res.data.companyName;
                    this.signTime = res.data.finishTime;
                    this.contractName = res.data.contractName;
                    this.inviteeName = res.data.inviteeName;
                }).catch(err=>{
                    Toast.fail(err)
                })
            },
            // 合同预览
            signPreview() {
                const inviteId = sessionStorage.getItem("inviteId");
                DetailData.getDetailData(inviteId).then(res=>{
                    this.url = res.data.contractUrl;
                    this.$router.push({
                            name:"SignPreview",
                            params:{
                                pdfSrc: this.url,
                            },
                        }
                    );
                }).catch(err=>{
                    Toast.fail(err)
                })

            },
            // 合同下载
            downLoadSign() {
                const inviteId = sessionStorage.getItem("inviteId");
                DetailData.getDetailData(inviteId).then(res=>{
                    this.url = res.data.contractUrl;
                    download(this.url);
                    Toast.success('下载成功');
                }).catch(err=>{
                    Toast.fail(err)
                })

            },
            // 获取签约合同数量
            getSignCount() {
              const loginType = sessionStorage.getItem('loginType');
              companySignApi.getCompanySignCount(loginType).then((res) => {
                this.signCount = res.data
              })
            },
            toSignList() {
              if (this.signCount > 0) {

                this.$router.push(
                    {
                      name:"SignList",
                    }
                )
              } else {
                Toast.fail('无历史签约数据');
              }
            }
        },
    }
</script>

<style scoped>
    .contractFinishClass{
       margin: 15px;
    }
    .signRowClass{
        height: 35px;
    }
    .signInfoCellClass{
        font-weight: bold;
        font-size: 20px;
    }
    .signCellClass{
        font-size: 16px;
    }
    .bottom-div {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: dodgerblue;
        height: 35px;
    }
</style>
