import request from '@/router/request'

export default {
    /**获取签约详情信息*/
    getDetailData(inviteId) {
        return request({
            url: `/foreground/web/companySignTool/getSignDetailByInviteId/${inviteId}`,
            method: 'get'
        })
    },

}
