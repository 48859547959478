import request from '@/router/request'

export default {
  /**短信*/
  smsSend(data) {
    return request({
      url: `/foreground/web/companySignTool/sendSmsCodeByCompanyUser`,
      method: 'post',
      data: data
    })
  },
  /**登录*/
  login(data) {
    return request({
      url: `/foreground/web/companySignTool/loginByInitiator`,
      method: 'post',
      data: data
    })
  },
  /**短信*/
  inviteeSend(data) {
    return request({
      url: `/foreground/web/companySignTool/sendSmsCodeByInvitee`,
      method: 'post',
      data: data
    })
  },
  /**登录*/
  inviteeLogin(data) {
    return request({
      url: `/foreground/web/companySignTool/loginByInvitee`,
      method: 'post',
      data: data
    })
  },
  /**获取图片验证码*/
  getPictureCode() {
    return request({
      url : `/foreground/web/companySignTool/image/code`,
      method: 'get',
    })
  }
}
