<template>
  <div>
    <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <div>
      <van-loading v-if="showLoading" size="30px" vertical style="margin-top: 50%">加载中...</van-loading>
      <pdf v-for="item in numPages" :key="item" :src="pdfSrcCompute" :page="item" ref="pdf" @page-loaded="lastPageLoad"></pdf>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import {Toast} from "vant";
export default {
  name: "SignPreview",
  components:{
    pdf
  },
  data() {
    return {
      username: '',
      password: '',
      pdfSrc:"",
      numPages: null, // pdf 总页数
      submitShowFlag: true,
      showLoading: true,
    }
  },
  computed: {
    pdfSrcCompute(){
      // 处理pdfUrl返回
      let src =  pdf.createLoadingTask({
        url: this.pdfSrc,
        // 引入pdf.js字体，templ
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/',
        cMapPacked: true
      })
      return src ;
    }
  },
  created() {
    this.pdfSrc = this.$route.params.pdfSrc;
    this.getNumPages()
  },
  methods : {
    onClickLeft() {
      this.$router.go(-1)
    },
    // 计算pdf页码总数
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.pdfSrc)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
      }).catch(err => {
        Toast.fail('pdf 加载失败');
      })
    },
    lastPageLoad (num) {
      num === this.numPages ? this.showLoading = false : this.showLoading = true
    },
  }
}
</script>

<style scoped>
</style>