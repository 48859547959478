import request from '@/router/request'

export default {
    // 获取签约列表
    getSignList(query, param) {
        return request({
            url: `/foreground/web/companySignTool/signFinishList`,
            method: 'post',
            params: query,
            data: param
        })
    },
    // 获取带有有效期的签约合同地址信息
    getValidSignPdfUrl(query) {
        return request({
            url: `/foreground/web/companySignTool/getOssFileUrl`,
            method: 'get',
            params: query
        })
    }
}