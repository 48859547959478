export default {
    getParams(url){
        let cs = url.split('?')[1];                //获取?之后的参数字符串
        if (cs === undefined){
            return null;
        }
        let cs_arr = cs.split('&');                    //参数字符串分割为数组
        let cs_res ={};
        for(let i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
            cs_res[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
        }
        return cs_res;
    }
}
