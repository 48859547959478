import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/view/Login'
import CompanySign from '@/view/CompanySign'
import SignInfo from '@/view/SignInfo'
import SignList from '@/view/SignList'
import SubLogin from '@/view/SubLogin'
import Sign from '@/view/Sign'
import SignPreview from '@/view/SignPreview'
import ContractFinish from '@/view/ContractFinish'

Vue.use(Router)
//2.创建VueRouter对象
const routes =  [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/companySign',
    name: 'CompanySign',
    component: CompanySign
  },
  {
    path: '/signInfo',
    name: 'SignInfo',
    component: SignInfo
  },
  {
    path: '/signList',
    name: 'SignList',
    component: SignList
  },
  {
    path: '/subLogin',
    name: 'SubLogin',
    component: SubLogin
  },
  {
    path: '/sign',
    name: 'Sign',
    component: Sign
  },
  {
    path: '/signPreview',
    name: 'SignPreview',
    component: SignPreview
  },
  {
    path: '/contractFinish',
    name: 'ContractFinish',
    component: ContractFinish
  },
]
const router = new Router({
  mode: "history",
  routes
})
//路由守卫 判断用户是否登录
router.beforeEach((to,from,next)=>{
  next();
  //如果有token或者是登录页面
  if(sessionStorage.getItem('token') || to.name == 'Login' || to.name == 'SubLogin' ){
    //继续下一个路由
    next();
  }else{
    //next();
    //没有token不允许
    next({
      name: 'Login',
    })
  }
})
export default router
