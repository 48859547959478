import axios from 'axios'
import Vue from 'vue';
import { Dialog } from 'vant';
import { Toast } from 'vant';
Vue.use(Dialog);

//加载ing样式
let loading;
let showLoading = ()=>{
  loading = Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 0
  });
};
let hideLoading = ()=>{
   loading.clear();
};

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000, // 请求超时时间
})
axios.defaults.headers['Content-type']="application/json; charset=utf-8"
axios.defaults.headers['Access-Control-Allow-Origin']= "*";
// request拦截
service.interceptors.request.use(config => {

    // 设置token
  if (sessionStorage.getItem('token')) {
    config.headers['web-token'] = sessionStorage.getItem('token');
  };
  if (sessionStorage.getItem('sessionId')) {
    config.headers['session-id'] = sessionStorage.getItem('sessionId');
  }
    // showLoading();
  return config;
}, error => {
  return Promise.reject(error);
})
// response 拦截器
service.interceptors.response.use(
    response => {
      // hideLoading();
      if (response.status !== 200) {
        Toast.fail('网络异常')
        return Promise.reject('error')
      }
      else {
          if (response.data.code !== 0) {
              if (response.data.code === 91 || response.data.code === 92 || response.data.code === 93 || response.data.code == 401) {
                  let loginType = "1";
                  if (sessionStorage.getItem("loginType") == "2"){
                      loginType = "2";
                  }
                  localStorage.clear();//清空缓存
                  sessionStorage.clear();//清空缓存
                  sessionStorage.setItem("loginType",loginType);
              }
              if (response.data.msg) {
                  Toast.fail(response.data.msg)
              }
              if (response.data.message) {
                  Toast.fail(response.data.message)
              }
            return Promise.reject('error')
          }
          // 缓存sessionid
          if (response.headers['session-id']) {
            sessionStorage.setItem('sessionId', response.headers['session-id']);
          }
      }
      return response.data
    },
    error => {
      // hideLoading();
      Toast.fail('系统错误');
      console.log('err' + error) // for debug
      //Toast(error.message)
      // hideLoading();
      return Promise.reject(error)
    }
)

export default service
