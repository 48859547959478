export default {
    //判断输入的手机号是否合法
    validatePhone(phone){

        if(!phone) {
            return {
                phone:"手机号码不能为空"
            }
            // return false
        } else if(!/^1[345678]\d{9}$/.test(phone)) {
            return {
                phone:"请输入正确是手机号"
            }
        } else {
            return true;
        }
    },
    //判断输入的短信验证码是否合法
    validateSMS(sms){

        if(!sms) {
            return {
                sms:"短信验证码不能为空"
            }
            // return false
        } else if(!/^\d{6}$/.test(sms)) {
            return {
                sms:"是请输入正确短信验证码"
            }
        } else {
            return true;
        }
    },
}
