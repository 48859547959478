import request from '@/router/request'

export default {
    // 获取企业合同信息列表
    getCompanyContactList() {
        return request({
            url: `/foreground/web/companySignTool/companySignTemplateListByCompanyUser`,
            method: 'get'
        })
    },
    // 获取签约合同数量
    getCompanySignCount(loginType) {
        return request({
            url: `/foreground/web/companySignTool/signFinishListCount/${loginType}`,
            method: 'get'
        })
    },
    // 获取企业信息
    getCompanyInfo(companyCode) {
        return request({
            url: `/foreground/web/companySignTool/selectCompanyByTyshxydmNo/${companyCode}`,
            method: 'get'
        })
    },
    // 根据身份证号 查询个人信息
    getPersonInfo(personUserCard) {
        return request({
            url: `/foreground/web/companySignTool/selectIndividualUserByUserCard/${personUserCard}`,
            method: 'get'
        })
    },
    // 发起邀约 生成二维码数据按钮
    createCompanySign(param) {
        return request({
            url: `/foreground/web/companySignTool/createCompanySignInvite`,
            method: 'post',
            data: param
        })
    }
}
